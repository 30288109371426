import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const HomeMenu = [
  /* {
        name: 'It Solution',
        routerPath: '/'
    },
    {
        name: 'Software Development',
        routerPath: '/software-development'
    },
    {
        name: 'Cyber Security',
        routerPath: '/cyber-security'
    }, */
];

const Miscellaneous = [
  {
    name: "About Us",
    routerPath: "/about",
  },
  {
    name: "Services",
    routerPath: "/services",
  },
  {
    name: "Services Details",
    routerPath: "/services-details",
  },
  {
    name: "Team",
    routerPath: "/team",
  },
  {
    name: "Team Details",
    routerPath: "/team-details",
  },

  {
    name: "Price",
    routerPath: "/price",
  },
  {
    name: "FAQ",
    routerPath: "/faq",
  },
  {
    name: "Error-404",
    routerPath: "/error",
  },
  {
    name: "Login",
    routerPath: "/login",
  },
  {
    name: "Signup",
    routerPath: "/signup",
  },
  {
    name: "Comming Soon",
    routerPath: "/coming-soon",
  },
];

const ProjectMenu = [
  {
    name: "Project Grid",
    routerPath: "/project-grid",
  },
  {
    name: "Project Masonry",
    routerPath: "/project-masonry",
  },
  {
    name: "Project Details",
    routerPath: "/project-details",
  },
];
const BlogMenu = [
  {
    name: "Blog Grid",
    routerPath: "/blog-grid",
  },
  {
    name: "Blog Masonry",
    routerPath: "/blog-masonry",
  },
  {
    name: "Blog Classic",
    routerPath: "/blog-classic",
  },
  {
    name: "Blog Details V1",
    routerPath: "/blog-details-v1",
  },
  {
    name: "Blog Details V2",
    routerPath: "/blog-details-v2",
  },
];

const ThemeMainMenu = () => {
  const scrollToElementById = (elementId) => {
    const targetElement = document.getElementById(elementId);
    if (targetElement) {
      const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - 100;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleLinkClick = (e, elementId) => {
    e.preventDefault();
    if (window.location.hash === `#${elementId}`) {
      return;
    }
    scrollToElementById(elementId);
  };

  return (
    <Fragment>
      <ul className="navbar-nav main-navbar mb-2 mb-lg-0" style={{ whiteSpace: "nowrap" }}>
        {/* <li className="nav-item">
          <a className="nav-link" href="#">
            Home
          </a>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link"
            href="#"
            id="navbarDropdownTwo"
            onClick={(e) => handleLinkClick(e, "about")}
          >
            About
          </a>
        </li> */}
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" onClick={(e) => handleLinkClick(e, "tokenomics")}>
            Tokenomics
          </a>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownThree"
            onClick={(e) => handleLinkClick(e, "roadmap")}
          >
            Roadmap
          </a>
        </li>
        {/* <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownThree"
            onClick={(e) => handleLinkClick(e, "team")}
          >
            Team
          </a>
        </li> */}
        <li className="nav-item">
          <Link className="nav-link" to="/#" onClick={(e) => handleLinkClick(e, "howitworks")}>
            How it Works
          </Link>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link" href="/GoToken-EN.pdf" target="_blank">
            Whitepaper-EN
          </a>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link" href="/GoToken-TR.pdf" target="_blank">
            Whitepaper-TR
          </a>
        </li>
        <li
          className="nav-item"
          style={{
            marginRight: "4px",
          }}
        >
          {/* <a
            className="nav-link"
            style={{
              color: "#fff",
              backgroundColor: "#EDD051",
              paddingLeft: "8px",
              paddingRight: "8px",
              borderRadius: "5px",
              marginRight: "4px",
            }}
            href="https://presale.gotoken.com.tr"
            target="_blank"
          >
            PRESALE
          </a> */}
        </li>
        {/* <li className="nav-item">
          <Link
            className="nav-link"
            to="/#"
            onClick={(e) => handleLinkClick(e, "teamss")}
          >
            Team
          </Link>
        </li> */}
      </ul>
    </Fragment>
  );
};

export default ThemeMainMenu;
