import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const featureSilderContent = [
  {
    img: "img-01",
    bTitle: "Maksut Coşkun Dokunulmaz",
    title: "Ceo, Founder",
  },
  // {
  //   img: "img-02",
  //   bTitle: "Özlem Keleş",
  //   title: "Kurumsal İlişkiler",
  // },
  {
    img: "img-03",
    bTitle: "Halil Kaya",
    title: "Developer",
  },
  {
    img: "img-03",
    bTitle: "Bayram Candan",
    title: "İdari İlişkiler",
  },
  {
    img: "img-01",
    bTitle: "Rüzgar Dursun Yüce",
    title: "Topluluk Yöneticisi",
  },
  // {
  //   img: "img-03",
  //   bTitle: "Bünyamin Üstün",
  //   title: "Metaverse Developer",
  // },
  // {
  //   img: "img-03",
  //   bTitle: "Gökhan Eryiğit",
  //   title: "Developer",
  // },
  {
    img: "img-01",
    bTitle: "Coşkun Günay",
    title: "İş Geliştirme",
  },
  // {
  //   img: "img-03",
  //   bTitle: "Abdullah Aydın",
  //   title: "Topluluk Yöneticisi",
  // },
  {
    img: "img-03",
    bTitle: "Burak Torun",
    title: "Sosyal Medya Yönetimi",
  },
  {
    img: "img-01",
    bTitle: "Yılmaz Bozan",
    title: "Dijital Pazarlama Yönetimi",
  },
  {
    img: "img-03",
    bTitle: "Yusuf Can Güneş",
    title: "Medya İlişkileri",
  },
  // {
  //   img: "img-03",
  //   bTitle: "Bülent Kavaklı",
  //   title: "Danışman",
  // },
  // {
  //   img: "img-01",
  //   bTitle: "Eyüp Salih Subaşı",
  //   title: "Danışman",
  // },
  // {
  //   img: "img-03",
  //   bTitle: "Cemil Demiryürek",
  //   title: "Danışman",
  // },
  {
    img: "img-01",
    bTitle: "Sadi Kündüroğlu",
    title: "Danışman",
  },
  // {
  //   img: "img-01",
  //   bTitle: "Melisa Nur Keser",
  //   title: "Topluluk Yöneticisi",
  // },
  // {
  //   img: "img-03",
  //   bTitle: "Şaziye Nur Baş",
  //   title: "Topluluk Yöneticisi",
  // },
];

const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  loop: true,
  autoplay: true,
  autoplaySpeed: 2000,
  slidesToShow: 3,
  slidesToScroll: 1,
  padding: 15,
  center: false,
  centerPadding: 0,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },

    {
      breakpoint: 767.98,
      settings: {
        arrows: false,
        slidesToShow: 1,
      },
    },
  ],
};

//feature-slider-active
const FeatureSliderOne = () => {
  return (
    <Fragment>
      <Slider {...settings} className="feature-slider row gx-0">
        {featureSilderContent.map((val, i) => (
          <div key={i} className="col-lg-4">
            <div className="feature-item">
              <Link className="" to="">
                {/* <img
                  className="w-100"
                  src={`assets/img/feature/${val.img}.jpg`}
                  alt="img"
                /> */}
              </Link>
              <h5>
                <Link to="">{val.title}</Link>
              </h5>
              <h3>
                <Link to="">{val.bTitle}</Link>
              </h3>
            </div>
          </div>
        ))}
      </Slider>
    </Fragment>
  );
};

export default FeatureSliderOne;
