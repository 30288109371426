import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import CopyRightOne from "./CopyRightOne";

const FooterNav = [
  {
    name: "About Us",
    routerPath: "/about",
  },
  {
    name: "Contact Us",
    routerPath: "/contact",
  },
  {
    name: "Blog",
    routerPath: "/blog-grid",
  },
  {
    name: "policy",
    routerPath: "/faq",
  },
  {
    name: "Refund",
    routerPath: "/faq",
  },
];

const FooterOne = () => {
  return (
    <Fragment>
      <footer className="footer-area pt-140 pt-lg-95">
        <img
          className="fot-shape-one"
          src="assets/img/shape/line-round-4a.svg"
          alt="footer shape"
        />
        <div className="container">
          <div className="row gx-4 gx-xxl-5 mb-1">
            <div className="col-lg-4 col-md-8">
              <div
                className="footer__widget mb-30"
                data-aos="fade-right"
                data-aos-delay={200}
              >
                {/* <p className="pe-xl-3 foter-para">
                  Premium WordPress template for Cryptocurrency Consulting
                  Company. Special features: Live Exchange Rates Graph, Exchange
                  Rates Widgets, Real-Time Cryptocurrency Converter.
                </p> */}
                {/*<Link className="foter-btn" to="/contact">Let’s Chat
                                    <i className="bi bi-arrow-up-right"/>
    </Link> */}

                <img
                  src="/assets/img/genclik-otobusu.png"
                  alt="Genclik Otobusu"
                  width={320}
                />
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4"
              data-aos="fade-up"
              data-aos-delay={300}
            ></div>
            <div className="col-lg-4" data-aos="fade-left" data-aos-delay={200}>
              <div className="footer__widget mb-30 ps-xl-5">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",

                    gap: "10px",
                  }}
                >
                  <i className="fal fa-envelope-open" />
                  <h4 className="text-white mb-40">mcd@mcdgenclikajansi.com</h4>
                </div>

                <address>
                  <i className="fal fa-address-book mr-10" />
                  Fulya Mah. Büyükdere Cad. No 76 Kat 13 Daire 188 Quasar Şişli
                  / İstanbul
                </address>
                <a
                  href="https://genclikdunyasi.com/"
                  target="_blank"
                  style={{ fontWeight: "bold" }}
                >
                  genclikdunyasi.com
                </a>
              </div>
            </div>
          </div>
        </div>
        <CopyRightOne />
      </footer>
    </Fragment>
  );
};

export default FooterOne;
