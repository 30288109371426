import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

//components
import ThemeMenuOne from "../../components/header/ThemeMenuOne";
import ThemeBannerOne from "../../components/theme-banner/ThemeBannerOne";
import MarqueSlider from "../../components/marque/MarqueSlider";
import BrandOne from "../../components/brand/BrandOne";
import BlockStyleOne from "../../components/block/BlockStyleOne";
import FeatureOne from "../../components/feature/FeatureOne";
import FeatureSliderOne from "../../components/feature-slider/FeatureSliderOne";
import FeatureTwo from "../../components/feature/FeatureTwo";
import CounterOne from "../../components/counter/CounterOne";
import FeedbackOne from "../../components/testimonial/FeedbackOne";
import BlogOne from "../../components/blog/BlogOne";
import NewsletterOne from "../../components/call-to-action/NewsletterOne";
import FooterOne from "../../components/footer/FooterOne";

const ItSolution = () => {
  return (
    <Fragment>
      <div className="main-page-wrapper">
        {/* Helmet start */}
        <Helmet>
          <title>Go Token</title>
        </Helmet>
        {/* Helmet end */}

        {/*ThemeMenuOne start*/}
        <ThemeMenuOne />
        {/*ThemeMenuOne end*/}

        {/*ThemeBannerOne start*/}
        <ThemeBannerOne />
        {/*ThemeBannerOne end*/}

        {/*marque start*/}
        {/* <MarqueSlider /> */}
        {/*marque end*/}

        {/*brand-area start*/}
        <section className="brand-area pt-100 pb-125 pt-lg-60 pb-lg-90">
          <div className="container">
            <div className="row gx-4 gx-xxl-5">
              {/* BrandOne start */}
              <BrandOne />
              {/* BrandOne end */}
            </div>
          </div>
        </section>
        {/*brand-area end*/}

        {/*about-techy start*/}
        <section id="about" className="about-techy position-relative pt-115 pb-15">
          <div className="container">
            <div className="row gx-4 gx-xxl-5 align-items-center">
              <div className="col-lg-7" data-aos="fade-right" data-aos-delay={100}>
                <div className="position-relative">
                  <div className="logo-animated-block" style={{ bottom: 100 }}>
                    {/* <img
                      className="rotate-img"
                      src="assets/img/shape/mask-text.svg"
                      alt="Text Img"
                    /> */}
                    <div className="mark-icon">
                      <img src="assets/img/logo/header-logo-1.png" alt="GoToken" width={110} />
                    </div>
                  </div>
                  <div className="about-img-wrapper position-relative mb-45">
                    <img className="main-img clip" style={{ marginTop: 60 }} src="assets/img/gotoken-prag.jpeg" alt="img" />
                    <img className="shape-one" src="assets/img/shape/line-round-2a.svg" alt="shape" />
                  </div>
                </div>
              </div>
              <div className="col-lg-5" data-aos="fade-left" data-aos-delay={100}>
                {/* BlockStyleOne start */}
                <BlockStyleOne />
                {/* BlockStyleOne end */}
              </div>
            </div>
          </div>
        </section>
        {/*about-techy end*/}

        <ul className="website-links">
          <li className="">
            <a className="remove-hover" href="https://www.turkiyegenclikbulusmasi.com" target="_blank">
              <span className="img-bg">
                <img src="assets/img/icon/icon-2a.svg" alt="icon" />
              </span>
              <span>Türkiye Gençlik Buluşması</span>
            </a>
          </li>
          <li className="">
            <a className="remove-hover" href="https://www.genclikotobusu.com/" target="_blank">
              <span className="img-bg">
                <img src="assets/img/icon/icon-2a.svg" alt="icon" />
              </span>
              <span>Gençlik Otobüsü</span>
            </a>
          </li>
          <li className="">
            <a className="remove-hover" href="https://www.genclikdunyasi.com/" target="_blank">
              <span className="img-bg">
                <img src="assets/img/icon/icon-2a.svg" alt="icon" />
              </span>
              <span>Gençlik Dünyası</span>
            </a>
          </li>
        </ul>

        {/*our-services start*/}
        <section className="our-services pt-80 pb-105 pt-lg-45 pb-lg-15" id="tokenomics">
          <div className="container">
            <div className="row gx-4 gx-xxl-5 justify-content-lg-start justify-content-center">
              <div className="col-xl-5 col-lg-6 col-md-8">
                <div className="section-title text-center text-lg-start mb-55">
                  <h3 className="sect-title mb-20" data-aos="fade-upt">
                    Tokenomics
                  </h3>
                  <p
                    data-aos="fade-right"
                    data-aos-delay={100}
                    style={{
                      fontSize: "18px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Total Supply: 1.000.000.000 Tokens
                  </p>
                  <p
                    data-aos="fade-right"
                    data-aos-delay={100}
                    style={{
                      fontSize: "18px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Contractor: Binance Smart Chain
                  </p>
                  <a
                    href="https://bscscan.com/address/0x7dc83e022c513Cb88c0FE97Be931C743B99a09C3"
                    target="_blank"
                    data-aos="fade-right"
                    data-aos-delay={100}
                    className="contract-address"
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    Contract Address: 0x7dc83e022c513Cb88c0FE97Be931C743B99a09C3
                  </a>
                </div>
              </div>
            </div>
            <div className="row gx-4 gx-xxl-5">
              {/* FeatureOne start */}
              <FeatureOne />

              {/* FeatureOne end */}
            </div>
            {/* <img className="" style={{ width: "100%", height: "auto" }} src="assets/img/tokenomics.png" alt="Tokenomics" /> */}
          </div>
        </section>
        {/*our-services end*/}

        {/*feature-work start*/}
        <section className="feature-two pt-135 pb-30 pt-lg-60 pb-lg-15" id="howitworks">
          <div className="container">
            <div className="row gx-4 gx-xxl-5 justify-content-lg-start justify-content-center">
              <div className="col-xl-5 col-lg-6 col-md-8">
                <div className="section-title text-lg-start text-center mb-60">
                  <h3 className="sect-title mb-25">How It Works</h3>
                  <p>
                    By buying{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        marginRight: "4px",
                        color: "#FFD601",
                        fontWeight: "bold",
                      }}
                    >
                      GO
                    </span>
                    Token, you get a change to travel 36 different countries all around the world. With the power of blockchain
                    you can be sore of the fairness and equality between you and other owners of{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        marginRight: "4px",
                        color: "#FFD601",
                        fontWeight: "bold",
                      }}
                    >
                      GO
                    </span>
                    Token.
                  </p>
                </div>
              </div>
            </div>
            <div className="row gx-4 gx-xxl-5">
              {/* FeatureTwo start */}
              <FeatureTwo />
              {/* FeatureTwo end */}
            </div>
          </div>
          <img className="shape-one" src="assets/img/shape/line-round-1.svg" alt="" />
        </section>
        {/*feature-work end*/}
        <img className="main-img travel-img" src="assets/img/gotoken-paris.jpeg" alt="img" />

        {/*client-feedback start*/}
        <section className="client-feedback pt-140 pb-150 pt-lg-55 pb-lg-60" id="roadmap">
          <div className="container">
            <div className="row gx-4 gx-xxl-5 justify-content-center" data-aos="fade-up">
              <div className="col-xl-5 col-lg-6 col-md-8">
                <div className="section-title text-center mb-55">
                  <h3 className="sect-title mb-25">Road Map</h3>
                  <p></p>
                </div>
              </div>
            </div>
            {/* FeedbackOne start */}
            <FeedbackOne />
            {/* FeedbackOne start */}
          </div>
        </section>
        {/*client-feedback end*/}

        <section className="client-feedback pt-140 pb-150 pt-lg-55 pb-lg-60" id="advantages">
          <div className="container">
            <div className="row gx-4 gx-xxl-5 justify-content-center" data-aos="fade-up">
              <div className="col-xl-5 col-lg-6 col-md-8">
                <div className="section-title text-center mb-55">
                  <h3 className="sect-title mb-25">Advantages</h3>
                  <p style={{ fontSize: 24, textAlign: "center" }}>
                    <span
                      style={{
                        fontSize: "24px",
                        marginRight: "4px",
                        color: "#FFD601",
                        fontWeight: "bold",
                      }}
                    >
                      GO
                    </span>
                    Token holders will be eligible for many advantages presented by the{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        marginRight: "4px",
                        color: "#FFD601",
                        fontWeight: "bold",
                      }}
                    >
                      GO
                    </span>
                    e-commerce site or other affiliates.
                    <br />
                    <br />
                    <span
                      style={{
                        fontSize: "24px",
                        marginRight: "4px",
                        color: "#FFD601",
                        fontWeight: "bold",
                      }}
                    >
                      GO
                    </span>
                    Token holders can attend vacation lotteries for Rome, Paris and more.
                    <br />
                    <br />
                    Youth card owners will be rewarded with{" "}
                    <span
                      style={{
                        fontSize: "24px",
                        marginRight: "4px",
                        color: "#FFD601",
                        fontWeight: "bold",
                      }}
                    >
                      GO
                    </span>{" "}
                    Token.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="flex justify-content-center align-items-center" style={{}}>
          <img src="/assets/img/gotoken-travel.jpeg" className="main-img travel-img" alt="" style={{}} />
        </div>

        <section className="client-feedback pt-140 pb-150 pt-lg-55 pb-lg-60" id="projects">
          <div className="container">
            <div className="row gx-4 gx-xxl-5 justify-content-center" data-aos="fade-up">
              <div className="col-xl-5 col-lg-6 col-md-8">
                <div className="section-title text-center mb-55">
                  <h3 className="sect-title mb-25">Our Projects</h3>
                  <p style={{ fontSize: 24, textAlign: "center" }}>
                    <a href="https://www.genclikotobusu.com/" target="_blank">
                      Youth Bus (GO)
                    </a>
                    <br />
                    <br />
                    <a
                      href="https://www.mcdgenclikkulubu.org/albumler/turkiye-genclik-odulleri-2022/"
                      target="_blank"
                      // style={{ color: "yellow" }}
                    >
                      Türkiye Youth Awards
                    </a>
                    <br />
                    <br />

                    <a href="https://www.turkiyegenclikbulusmasi.com/" target="_blank">
                      Türkiye Youth Symposium
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*feature-work start*/}
        <section className="feature-work-area pt-90 pb-95 pt-lg-50 pb-lg-60" id="team">
          <div className="container">
            <div className="row gx-4 gx-xxl-5 align-items-center">
              <div className="col-xl-5 col-lg-6 col-md-8">
                <div className="section-title text-center text-md-start mb-55">
                  <h3 className="sect-title mb-25" data-aos="fade-up">
                    Team
                  </h3>
                  <p data-aos="fade-up" data-aos-delay={100}></p>
                </div>
              </div>
            </div>
          </div>
          <div className="container feature-custom-container">
            <div className="feature-slider-one slick-nav">
              <FeatureSliderOne />
            </div>
          </div>
        </section>
        {/*feature-work end*/}

        {/*footer-area start*/}
        <FooterOne />
        {/*footer-area end*/}
      </div>
    </Fragment>
  );
};

export default ItSolution;
