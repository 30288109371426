import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./router/AppRouter";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="GoToken" />
        <meta name="description" content="GoToken" />
        <meta property="og:site_name" content="gotoken" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="GoToken" />
      </Helmet>
      <ScrollToTop />
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
