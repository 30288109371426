import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const featureContent = [
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-6a",
    backIcon: "icon-6w",
    title: "TEAM",
    desc: "15%",
    pview: "5%",
    dataAos: "fade-up",
    dataDelay: "150",
  },
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-6a",
    backIcon: "icon-6w",
    title: "Strategic Partnerships",
    desc: "5%",
    pview: "121 Projects",
    dataAos: "fade-up",
    dataDelay: "150",
  },
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-6a",
    backIcon: "icon-6w",
    title: "IEO Locked",
    desc: "7.5%",
    pview: "121 Projects",
    dataAos: "fade-up",
    dataDelay: "150",
  },
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-6a",
    backIcon: "icon-6w",
    title: "IEO Unlocked",
    desc: "12.5%",
    pview: "121 Projects",
    dataAos: "fade-up",
    dataDelay: "150",
  },
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-5a",
    backIcon: "icon-5w",
    title: "MARKETING",
    desc: "5%",
    pview: "15%",
    dataAos: "fade-up",
    dataDelay: "100",
  },
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-6a",
    backIcon: "icon-6w",
    title: "Liquidity",
    desc: "10%",
    pview: "121 Projects",
    dataAos: "fade-up",
    dataDelay: "150",
  },
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-6a",
    backIcon: "icon-6w",
    title: "Research & Development",
    desc: "5%",
    pview: "121 Projects",
    dataAos: "fade-up",
    dataDelay: "150",
  },
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-3a",
    backIcon: "icon-3w",
    title: "TRAVEL INVESTMENTS",
    desc: "40%",
    pview: "10%",
    dataAos: "fade-up",
    dataDelay: "",
  },

  /*
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-4a",
    backIcon: "icon-4w",
    title: "COMPANY RESERVE",
    desc: "20%",
    pview: "420 Projects",
    dataAos: "fade-up",
    dataDelay: "50",
  },
  */

  /*
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-7a",
    backIcon: "icon-7w",
    title: "Stake",
    desc: "25%",
    pview: "25%",
    dataAos: "fade-up",
    dataDelay: "200",
  },
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-8a",
    backIcon: "icon-8w",
    title: "Marketing",
    desc: "10%",
    pview: "224 Projects",
    dataAos: "fade-up",
    dataDelay: "250",
  },
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-8a",
    backIcon: "icon-8w",
    title: "Founders and Team",
    desc: "10%",
    pview: "224 Projects",
    dataAos: "fade-up",
    dataDelay: "250",
  },
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-8a",
    backIcon: "icon-8w",
    title: "Advisor",
    desc: "10%",
    pview: "224 Projects",
    dataAos: "fade-up",
    dataDelay: "250",
  },
  {
    arrow: "bi bi-arrow-up-right",
    frontIcon: "icon-8a",
    backIcon: "icon-8w",
    title: "airdrop",
    desc: "10%",
    pview: "224 Projects",
    dataAos: "fade-up",
    dataDelay: "250",
  },
  */
];

const FeatureOne = () => {
  return (
    <Fragment>
      {featureContent.map((val, i) => (
        <div key={i} className="col-lg-4 col-md-6" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
          <div className="feature-style-one mb-20">
            <h2>
              <Link>{val.title}</Link>
              <br />
              <br />
              <span className="">{val.desc}</span>
            </h2>
            {/* <p style={{ fontWeight: 600, fontSize: 24, color: "#fff" }}>
              {val.desc}
            </p> */}
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default FeatureOne;
