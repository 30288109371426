import React, { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BrandLogo = [
  {
    img: "italy",
  },
  {
    img: "germany",
  },
  {
    img: "netherlands",
  },
  {
    img: "greece",
  },
  {
    img: "austria",
  },
  {
    img: "hungary",
  },
  {
    img: "france",
  },
  {
    img: "bulgaria",
  },
  {
    img: "romania",
  },
  {
    img: "monaco",
  },
  {
    img: "czech",
  },
  {
    img: "vatican",
  },
];

const BrandOne = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Fragment>
      <Slider {...settings} className="brand-slider-one">
        {BrandLogo.map((val, i) => (
          <div className="brand-logo" key={i}>
            <a href="#">
              <img
                src={`assets/img/brand/${val.img}.svg`}
                alt="brand"
                width={60}
              />
            </a>
          </div>
        ))}
      </Slider>
    </Fragment>
  );
};

export default BrandOne;
