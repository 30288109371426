import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const BlockStyleOne = () => {
  return (
    <Fragment>
      <div className="block-style-one pt-10 mb-45">
        <h2 className="mb-15">
          Travel 32 countries and 46 cities with the power of blockchain.
        </h2>
        <p>
          <span
            style={{
              fontSize: "20px",
              marginRight: "4px",
              color: "#FFD601",
              fontWeight: "bold",
            }}
          >
            GO
          </span>{" "}
          offers travel opportunities for youth between the ages of 18-35.
        </p>

        <ul className="block-box list-none">
          <li>
            <a className="remove-hover">
              <span className="img-bg">
                <img src="assets/img/icon/icon-1a.svg" alt="icon" />
              </span>
              <span>Travel World</span>
            </a>
          </li>
          <li>
            <a className="remove-hover">
              <span className="img-bg">
                <img src="assets/img/icon/icon-2a.svg" alt="icon" />
              </span>
              <span>100% Fair for All</span>
            </a>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default BlockStyleOne;
