import React from "react";
import { Link } from "react-router-dom";
import MetisMenu from "@metismenu/react";
import "metismenujs/dist/metismenujs.css";

const HomeMenu = [
  {
    name: "It Solution",
    routerPath: "/",
  },
  {
    name: "Software Development",
    routerPath: "/software-development",
  },
  {
    name: "Cyber Security",
    routerPath: "/cyber-security",
  },
];

const Miscellaneous = [
  {
    name: "About Us",
    routerPath: "/about",
  },
  {
    name: "Services",
    routerPath: "/services",
  },
  {
    name: "Services Details",
    routerPath: "/services-details",
  },
  {
    name: "Team",
    routerPath: "/team",
  },
  {
    name: "Team Details",
    routerPath: "/team-details",
  },

  {
    name: "Price",
    routerPath: "/price",
  },
  {
    name: "FAQ",
    routerPath: "/faq",
  },
  {
    name: "Error-404",
    routerPath: "/error",
  },
  {
    name: "Login",
    routerPath: "/login",
  },
  {
    name: "Signup",
    routerPath: "/signup",
  },
  {
    name: "Comming Soon",
    routerPath: "/coming-soon",
  },
];

const ProjectMenu = [
  {
    name: "Project Grid",
    routerPath: "/project-grid",
  },
  {
    name: "Project Masonry",
    routerPath: "/project-masonry",
  },
  {
    name: "Project Details",
    routerPath: "/project-details",
  },
];
const BlogMenu = [
  {
    name: "Blog Grid",
    routerPath: "/blog-grid",
  },
  {
    name: "Blog Masonry",
    routerPath: "/blog-masonry",
  },
  {
    name: "Blog Classic",
    routerPath: "/blog-classic",
  },
  {
    name: "Blog Details V1",
    routerPath: "/blog-details-v1",
  },
  {
    name: "Blog Details V2",
    routerPath: "/blog-details-v2",
  },
];

const MobileMenu = (props) => {
  const { onClick, active } = props;

  const scrollToElementById = (elementId) => {
    const targetElement = document.getElementById(elementId);
    if (targetElement) {
      const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - 100;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleLinkClick = (e, elementId) => {
    e.preventDefault();
    if (window.location.hash === `#${elementId}`) {
      return;
    }
    scrollToElementById(elementId);
  };

  return (
    // <div className={active?"mobile-menu-wrapper show d-lg-none":"mobile-menu-wrapper d-lg-none"}>
    <div className={`mobile-menu-wrapper ${active} d-lg-none`}>
      <div className="mobile-logo mb-5">
        <Link to="/">
          <img src="assets/img/logo/header-logo-1.png" alt="" width={110} />
        </Link>
      </div>
      <div className="close-menu" onClick={onClick}>
        <i className="bi bi-x-lg"></i>
      </div>
      <div className="side-mobile-menu">
        <MetisMenu>
          {/* <li className="">
            <a href="#">Home</a>
          </li>
          <li className="" onClick={(e) => handleLinkClick(e, "about")}>
            <a href="#">About </a>
          </li> */}
          <li className="" onClick={(e) => handleLinkClick(e, "tokenomics")}>
            <a href="#">Tokenomics</a>
          </li>
          <li className="" onClick={(e) => handleLinkClick(e, "roadmap")}>
            <a href="#">Roadmap</a>
          </li>
          <li className="" onClick={(e) => handleLinkClick(e, "team")}>
            <a href="#">Team</a>
          </li>
          <li className="" onClick={(e) => handleLinkClick(e, "howitworks")}>
            <a href="#">How It Works</a>
          </li>
          {/* <li className="" onClick={(e) => handleLinkClick(e, "teamss")}>
            <a href="#">Team</a>
          </li> */}

          <li className="">
            <a className="nav-link" href="/GoToken-EN.pdf" target="_blank">
              Whitepaper-EN
            </a>
          </li>
          <li className="">
            <a className="nav-link" href="/GoToken-TR.pdf" target="_blank">
              Whitepaper-TR
            </a>
          </li>
          <li
            className="nav-item"
            style={{
              marginRight: "4px",
            }}
          >
            {/* <a
              className="nav-link"
              style={{
                color: "#fff",
                backgroundColor: "#EDD051",
                paddingLeft: "8px",
                paddingRight: "8px",
                borderRadius: "5px",
                marginRight: "4px",
              }}
              href="https://presale.gotoken.com.tr"
              target="_blank"
            >
              PRESALE
            </a> */}
          </li>
        </MetisMenu>
      </div>
      {/*<div className="login-btn text-center mt-4">
        <Link className="theme_btn w-100" to="/login">
          Login
        </Link>
        <p className="text-white">
          Don't have an account ? Please{" "}
          <Link to="/signup" className="border-bottom">
            Signup
          </Link>{" "}
          today
        </p>
      </div>*/}
    </div>
  );
};

export default MobileMenu;
