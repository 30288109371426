import React, { Fragment } from "react";
import SocialOne from "../social/SocialOne";

const CopyRightOne = () => {
  return (
    <Fragment>
      <div className="techy-copyright pt-60 pb-45 pb-lg-10">
        <div className="container">
          <div className="row gx-4 gx-xxl-5 align-items-center">
            <div className="col-md-6 text-center text-md-start">
              <div className="copyright mb-30">
                <h6>Copyright © 2023. All rights reserved</h6>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <SocialOne />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CopyRightOne;
