import React, { Fragment } from "react";
import Slider from "react-slick";

const FeedbackContent = [
  {
    icon: [
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
    ],
    num: 4.5,
    desc: "Release of GO Token",
    avatar: "author-1",
    // author: "2023",
    desig: "2023",
  },
  {
    icon: [
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
    ],
    num: 5,
    desc: "Staking Opportunities for GO Owners",
    avatar: "author-2",
    //  author: 'Dev Hunter',
    desig: "2024",
  },
  {
    icon: [
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
    ],
    num: 5,
    desc: "Vacation Lotteries for GO Holders",
    avatar: "author-3",
    // author: 'Hamina Hasan',
    desig: "2025 | H1",
  },
  {
    icon: [
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
    ],
    num: 5,
    desc: "Expansion of Youth Bus project 60+ available countries 100+ cities.",
    // author: 'Hamina Hasan',
    desig: "2025 | H2",
  },
  {
    icon: [
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
    ],
    num: 5,
    desc: "Completed GO Ecosystem and integrated digital tourism",
    avatar: "author-3",
    // author: 'Hamina Hasan',
    desig: "2026",
  },

  /*
  {
    icon: [
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
    ],
    num: 5,
    desc: "Development of the ICO hedging platform",
    avatar: "author-3",
    // author: 'Hamina Hasan',
    desig: "6",
  },
  {
    icon: [
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
      "bi bi-star-fill",
    ],
    num: 5,
    desc: "General Sale",
    avatar: "author-3",
    // author: 'Hamina Hasan',
    desig: "7",
  },
  */
];

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  loop: true,
  autoplay: true,
  autoplaySpeed: 3500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const FeedbackOne = () => {
  return (
    <Fragment>
      <Slider
        {...settings}
        className="row feedback-active-one justify-content-center"
        data-aos="fade-up"
        data-aos-delay={0.01}
      >
        {FeedbackContent.map((val, i) => (
          <div key={i} className="col-lg-11">
            <div className="feedback-style-one text-center">
              <div className="rating mb-40"></div>
              <p>{val.desc}</p>
              <div className="author d-flex align-items-center justify-content-center mt-45">
                <div className="author-info">
                  <h3>{val.author}</h3>
                  <h5 style={{ fontSize: 20 }}>{val.desig}</h5>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </Fragment>
  );
};

export default FeedbackOne;
