import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const SocialIcon = [
  {
    icon: "fab fa-facebook-f",
    routerPath: "https://facebook.com/genclikotobusu",
  },
  {
    icon: "fab fa-twitter",
    routerPath: "https://twitter.com/genclikotobusu",
  },
  {
    icon: "fab fa-instagram",
    routerPath: "https://instagram.com/genclikotobusu",
  },
  {
    icon: "fab fa-youtube",
    routerPath: "https://youtube.com/@genclikotobusu",
  },
  {
    icon: "fab fa-telegram",
    routerPath: "https://t.me/s/GenclikOtobusu",
  },
];

const SocialOne = () => {
  return (
    <Fragment>
      <div className="footer__social mb-30">
        {SocialIcon.map((val, i) => (
          <a key={i} href={val.routerPath} target="_blank">
            <i className={val.icon} />
          </a>
        ))}
      </div>
    </Fragment>
  );
};

export default SocialOne;
