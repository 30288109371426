import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const featureContentTwo = [
  {
    icon: "icon-9a",
    title: "No limits, no borders.",
    desc: "No borders when you travel means no commission fees for international transsactions.",
    dataAos: "fade-up",
    dataDelay: "",
  },
  {
    icon: "icon-10a",
    title: "20x faster and efficiennt transactions.",
    desc: "Powered by Binance Smart Chain network you can transfer your GO Tokens whwerever you want whenever you want.",
    dataAos: "fade-up",
    dataDelay: "100",
  },
  {
    icon: "icon-11a",
    title: "100% Fairness.",
    desc: "Transparency feature of blockchain Assures you have the same amount of chance with the others.",
    dataAos: "fade-up",
    dataDelay: "200",
  },
];

const FeatureTwo = () => {
  return (
    <Fragment>
      {featureContentTwo.map((val, i) => (
        <div
          key={i}
          className="col-lg-4 col-md-6"
          data-aos={val.dataAos}
          data-aos-delay={val.dataDelay}
        >
          <div className="feature-style-two mb-45">
            {/*   <div className="icon"><img src={`assets/img/icon/${val.icon}.png`}alt="icon" /></div> */}
            <h3>{val.title}</h3>
            <p>{val.desc}</p>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default FeatureTwo;
